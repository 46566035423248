<template>
	<a target="_blank" rel="noopener noreferrer" :href="getUrl({ ...props })">
		<slot />
	</a>
</template>

<script setup>
import BookingEngine from '@becurious/hoteliers';

const { locale } = useI18n();
const bookingEngine = useState('bookingEngine', () => {});

const props = defineProps({
	language: { type: String, default: '' },
	arrival: { type: String, default: '' },
	departure: { type: String, default: '' },
	roomid: { type: String, default: '' },
});

onMounted(() => {
	if (!bookingEngine.value) {
		bookingEngine.value = new BookingEngine({
			hotelID: '3975',
		});
	}
});

const getUrl = (options) => {
	if (!options.language) {
		options = { ...options, language: locale.value };
	}

	return bookingEngine.value && bookingEngine.value.getUrl(options);
};
</script>

<style scoped>
span {
	cursor: pointer;
}
</style>
